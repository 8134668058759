import React from 'react';

import { useRouter } from 'next/router';

import { useTranslation } from 'i18n/hooks';
import { USER_ROUTES } from 'routes';
import Image from 'next/image';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { ShopPage } from 'modules/common/components/ShopPage';
import { LoginForm } from '../components/LoginForm';

export const LoginPage = () => {
  const { tu } = useTranslation('auth');
  const router = useRouter();
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    router.replace(USER_ROUTES.ACCOUNT);
  }

  return (
    <ShopPage title={tu('login', 'titles')} noFooterTopSpace>
      <div className="flex flex-col md:grid md:grid-cols-2">
        <div className="flex h-full min-h-[80vh] w-full flex-1 items-center p-4">
          <div className="flex w-full flex-col items-center">
            <LoginForm />
          </div>
        </div>
        <div className="relative w-full">
          <Image
            src="https://res.cloudinary.com/revibe/image/upload/v1645700016/home/115809346_715661895671679_2306125644346935595_n_2_ysz7la.jpg"
            alt="signup"
            className="object-cover object-left"
            fill
          />
        </div>
      </div>
    </ShopPage>
  );
};
