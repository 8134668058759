
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { LoginPage } from 'modules/auth/pages/LoginPage';
export default LoginPage;

    async function __Next_Translate__getStaticProps__18a128d3140__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/login/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18a128d3140__ as getStaticProps }
  